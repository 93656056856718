<template>
  <ul class="kt-menu__nav">
    <div v-if="customerLogo" class="poschodoch-spravca-header">
      <img :src="customerLogo" alt />
    </div>
    <template v-for="(menu, i) in menuItems">
      <KTMenuItem v-bind:menu="menu" :key="i"></KTMenuItem>
    </template>
  </ul>
</template>

<script>
import KTMenuItem from "@/views/theme/header/MenuItem.vue";
import menuConfig from "@/common/config/menu.config.json";
import ApiService from "@/common/api.service";
import UIService from "@/common/ui.service";

export default {
  name: "KTHeaderMenu",
  data() {
    return {
      items: [],
      imgContent: "",
    };
  },
  components: {
    KTMenuItem,
  },
  mounted() {
    // loading company info
    ApiService.setHeader();
    ApiService.get("Dashboard/CompanyInfo").then(({ data }) => {
      UIService.changeSiteColor(data.BackgroundColor);
      this.imgContent = data.LogoContent;
    });

    // loading menu
    ApiService.setHeader();
    ApiService.get("Dashboard/CompanyMenu").then(({ data }) => {
      this.items = [];

      for (let i = 0; i < data.length; i++) {
        // get menu item mapping
        let route = "custom-page?id=" + data[i].CompanyMenuId;

        // add new item
        this.items.push({
          title: data[i].Name,
          root: true,
          page: route,
          new: data[i].IsNew == 1,
        });
      }
    });
  },
  computed: {
    menuItems() {
      let menuItems = [...menuConfig.header.items];

      for (let i = 0; i < this.items.length; i++) {
        menuItems.push(this.items[i]);
      }

      return menuItems;
    },
    customerLogo() {
      return "data:image/png;base64," + this.imgContent;
    },
  },
};
</script>
