<template>
  <div
    id="kt_header_mobile"
    class="kt-header-mobile"
    style="justify-content: left !important;"
    v-bind:class="headerClasses"
  >
    <div class="poschodoch-mobile-left">
      <div
        class="kt-header-mobile__logo poschodoch-mobile-header"
        id="kt_aside_mobile_toggler"
      >
        <span class="kt-header-mobile__toggler poschodoch-mobile-logo">
          <img alt="Poschodoch" :src="headerLogo" />
        </span>
        <span class="poschodoch-open-mobile-menu">
          <i class="la la-angle-right"></i>
        </span>
      </div>
    </div>
    <div class="kt-header-mobile__toolbar " style="padding-right: 15px;">
      <button>
        <span></span>
      </button>

      <button class="kt-header-mobile__toggler" id="kt_header_mobile_toggler">
        <span></span>
      </button>

      <!--<button
        class="kt-header-mobile__topbar-toggler"
        id="kt_header_mobile_topbar_toggler"
        ref="kt_header_mobile_topbar_toggler"
      >
        <i class="flaticon-more"></i>
      </button>-->
    </div>

    <div
      class="kt-header-menu-wrapper"
      id="kt_header_menu_wrapper"
      ref="kt_header_menu_wrapper"
    >
      <div
        id="kt_header_menu"
        ref="kt_header_menu"
        class="kt-header-menu kt-header-menu-mobile poshodoch-spravca-menu"
        v-bind:class="headerMenuClasses"
      >
        <KTHeaderMenu v-if="headerMenuEnabled"></KTHeaderMenu>
      </div>
    </div>
    <div
      v-if="customerLogo"
      class="kt-header-mobile__logo poschodoch-mobile-spravca"
      style="justify-content: left !important;"
    >
      <img style="max-height: 60px !important" alt :src="customerLogo" />
    </div>

    <KTTopbar
      style="margin-top: 0px !important; left: auto !important"
    ></KTTopbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTToggle from "@/assets/js/toggle.js";
import ApiService from "@/common/api.service";
import KTTopbar from "@/views/theme/topbar/Topbar.vue";
import KTHeaderMenu from "@/views/theme/header/HeaderMenu.vue";

export default {
  name: "KTHeaderMobile",
  data() {
    return {
      imgContent: "",
    };
  },
  components: {
    KTTopbar,
    KTHeaderMenu,
  },
  mounted() {
    new KTToggle(this.$refs["kt_header_mobile_topbar_toggler"], {
      target: "body",
      targetState: "kt-header__topbar--mobile-on",
      togglerState: "kt-header-mobile__toolbar-topbar-toggler--active",
    });

    // loading company info
    ApiService.setHeader();
    ApiService.get("Dashboard/CompanyInfo").then(({ data }) => {
      this.imgContent = data.LogoContent;
    });
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      if (window.location.host.includes("enbra")) {
        return (
          process.env.BASE_URL +
          this.layoutConfig("self.logo-enbra.poschodoch-min")
        );
      }
      if (window.location.host.includes("poschodech")) {
        return (
          process.env.BASE_URL +
          this.layoutConfig("self.logo-cz.poschodoch-min")
        );
      }
      if (window.location.host.includes("penta")) {
        return (
          process.env.BASE_URL + this.layoutConfig("self.logo-penta.poschodoch")
        );
      }
      return (
        process.env.BASE_URL + this.layoutConfig("self.logo.poschodoch-min")
      );
    },
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },
    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
    customerLogo() {
      return "data:image/png;base64," + this.imgContent;
    },
  },
};
</script>
