<template>
  <!-- begin:: Footer -->
  <div
    class="no-print kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop poschodoch-footer"
    id="kt_footer"
  >
    <div
      class="kt-container"
      v-bind:class="{ 'kt-container--fluid': isFluid() }"
    >
      <div
        class="kt-footer__copyright  col-lg-12"
        style="display: flex; justify-content: center;"
      >
        <span
          class="kt-link col-lg-2 mb-1"
          style="text-decoration:none;"
          v-if="dateto != null"
        >
          {{ $i18n.t("LAYOUT.FOOTER.DATETO") }} {{ dateto }}
        </span>
        <router-link
          :to="{ name: 'registrationTerms', query: { type: 2 } }"
          class="kt-link mr-3 col-lg-3"
          target="_blank"
        >
          {{ $i18n.t("LAYOUT.FOOTER.LEGAL_A") }}
        </router-link>
        <router-link
          :to="{ name: 'registrationTerms', query: { type: 3 } }"
          class="kt-link mr-3 col-lg-3"
          target="_blank"
        >
          {{ $i18n.t("LAYOUT.FOOTER.LEGAL_Z") }}
        </router-link>
        <router-link
          :to="{ name: 'gdprTerms' }"
          class="kt-link mr-3 col-lg-3"
          target="_blank"
        >
          {{ $i18n.t("LAYOUT.FOOTER.PRIVACY") }}
        </router-link>
        <span class="kt-link col-lg-1" style="text-decoration:none;">
          Domus &nbsp;&copy;&nbsp; {{ year }}
        </span>
      </div>
    </div>
    <!--<div class="kt-footer__menu col-lg-2 mr-1">Domus &nbsp;&copy;&nbsp; {{year}}</div> -->
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/common/api.service";

export default {
  name: "KTFooter",
  data() {
    return {
      year: new Date().getUTCFullYear(),
      dateto: null,
    };
  },
  methods: {
    /**
     * Check if footer container is fluid
     * @returns {boolean}
     */
    isFluid() {
      return this.layoutConfig("footer.self.width") === "fluid";
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    // loading unit info from api
    ApiService.setHeader();
    ApiService.get("Dashboard/UnitInfo")
      .then(({ data }) => {
        this.dateto = data.DateTo;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
